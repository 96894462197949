import React from "react";

const WistiaRatioMaintainedContainer = ({ children }) => {
  return (
    <div className="help-large-section__video-player-container">
      <div className="help-large-section__video-player-ratio-container">
        {children}
      </div>
    </div>
  );
};

export default WistiaRatioMaintainedContainer;
