import { Grid } from "@material-ui/core";
import React from "react";
import HeaderComponent from "../../components/HeaderComponent/header-component";
import WistiaVideoPlayer from "../../components/wistia-video-player";
import { useMyBrokerInfo } from "../../hooks/broker.hooks";
import { useIsTablet } from "../../hooks/common.hooks";
import SharingButtonContainer from "./components/sharing-button-container";
import WistiaRatioMaintainedContainer from "../../components/wistia-ratio-maintained-container";

const SharingReferral = () => {
  const isTablet = useIsTablet();
  const { data: brokerInfo } = useMyBrokerInfo();
  return (
    <div className="help-and-support-page sharing-referral-page">
      <HeaderComponent headerContent={<div>Sharing & Referral</div>} />
      <Grid container className="help-and-support-page__main-container">
        <Grid
          item
          container
          alignItems="flex-start"
          className="help-large-section"
        >
          <Grid
            item
            container
            alignItems="center"
            className="help-large-section__container"
          >
            <Grid item xs={12} md={10} className="help-large-section__title">
              Refer your advisor to your friends
            </Grid>
            <Grid item xs={12} className="help-large-section__description">
              Our promise is to give your friends an amazing experience.
            </Grid>
            {isTablet && (
              <Grid item xs={12} className="help-large-section__description">
                {brokerInfo?.introductionVideoUrl ? (
                  <div className="help-large-section__video">
                    <WistiaRatioMaintainedContainer>
                      <WistiaVideoPlayer
                        key={brokerInfo.introductionVideoUrl}
                        embedId={brokerInfo.introductionVideoUrl}
                      />
                    </WistiaRatioMaintainedContainer>
                  </div>
                ) : (
                  brokerInfo?.imageUrl && (
                    <div className="help-large-section__image">
                      <img src={brokerInfo?.imageUrl} alt="broker avatar" />
                    </div>
                  )
                )}
              </Grid>
            )}
            <SharingButtonContainer />
          </Grid>

          {!isTablet && (
            <Grid
              item
              container
              justify="flex-end"
              alignItems="center"
              className="help-large-section__container"
            >
              {brokerInfo?.introductionVideoUrl ? (
                <div className="help-large-section__video">
                  <WistiaRatioMaintainedContainer>
                    <WistiaVideoPlayer
                      key={brokerInfo.introductionVideoUrl}
                      embedId={brokerInfo.introductionVideoUrl}
                    />
                  </WistiaRatioMaintainedContainer>
                </div>
              ) : (
                brokerInfo?.imageUrl && (
                  <div className="help-large-section__image">
                    <img src={brokerInfo?.imageUrl} alt="broker avatar" />
                  </div>
                )
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default SharingReferral;
